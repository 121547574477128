// src/components/ProductList.js
import React, { useState, useEffect } from 'react';
import { ResourceList, TextField, Button, Spinner, Avatar } from '@shopify/polaris';
import { getShopifyProducts } from '../apis';

function ProductList({ onSelect }) {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);
      try {
        const response = await getShopifyProducts();
        console.log(response[0]);

        const mappedProducts = response.map(product => {
          const firstVariant = product.node.variants.edges[0]?.node;
          return {
            id: product.node.id,
            title: product.node.title,
            image: product.node.images?.edges[0]?.node.url || '',
            imageId: product.node.images?.edges[0]?.node.id || '',
            mediaId: product.node.media?.edges[0]?.node.id || '',
            options: product.node.options.map(option => ({
              name: option.name,
              values: option.values
            })),
            price: firstVariant?.price,
            compareAtPrice: firstVariant?.compareAtPrice,
            weight: firstVariant?.inventoryItem?.measurement?.weight?.value || 0,
            weightUnit: firstVariant?.inventoryItem?.measurement?.weight?.unit || 'KILOGRAMS',
          };
        });

        setProducts(mappedProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
        // Handle error (e.g., show error message to user)
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleSelectionChange = (selectedIds) => {
    const selectedProductObjects = products.filter(product => selectedIds.includes(product.id));
    setSelectedProducts(selectedProductObjects);
  };

  const handleItemClick = (id) => {
    const newSelectedProducts = selectedProducts.includes(id)
      ? selectedProducts.filter(productId => productId !== id)
      : [...selectedProducts, id];
    handleSelectionChange(newSelectedProducts);
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setIsSearching(true);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setIsSearching(false);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  const filteredProducts = products.filter((product) =>
    product.title.toLowerCase().includes(searchValue.toLowerCase())
  );

  if (isLoading) {
    return <Spinner accessibilityLabel="Loading products" size="large" />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ flexGrow: 1, overflowY: 'auto', paddingBottom: '60px' }}>
        <TextField
          label="Search products"
          value={searchValue}
          onChange={handleSearchChange}
          clearButton
          onClearButtonClick={() => setSearchValue('')}
        />
        {isSearching && <Spinner accessibilityLabel="Searching products" size="small" />}
        <ResourceList
          items={filteredProducts}
          renderItem={(item) => (
            <ResourceList.Item
              id={item.id}
              accessibilityLabel={`Select ${item.title}`}
              media={
                <Avatar customer size="medium" name={item.title} source={item.image} />
              }
              onClick={() => handleItemClick(item.id)}
            >
              <h3>{item.title}</h3>
              <p>Price: ${item.price} | Compare at: ${item.compareAtPrice || 'N/A'}</p>
              <p>Weight: {item.weight} {item.weightUnit}</p>
              <p>Options: {item.options.map((opt) => opt.name).join(', ')}</p>
            </ResourceList.Item>
          )}
          selectedItems={selectedProducts.map(product => product.id)}
          onSelectionChange={handleSelectionChange}
          selectable
        />
      </div>
      <div style={{
        position: 'sticky',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '10px',
        background: 'white',
        boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
        zIndex: 1
      }}>
        <Button
          primary
          fullWidth
          onClick={() => {
            console.log('Sending selected products:', selectedProducts);
            onSelect(selectedProducts);
          }}
          disabled={selectedProducts.length === 0}
        >
          Add Selected Products ({selectedProducts.length})
        </Button>
      </div>
    </div>
  );
}

export default ProductList;