import React from 'react';
import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import '@shopify/polaris/build/esm/styles.css';
import AddVariantsPage from './components/AddVariantsPage';

function App() {
  return (
    <AppProvider i18n={enTranslations}>
      <AddVariantsPage />
    </AppProvider>
  );
}

export default App;