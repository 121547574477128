// src/components/NewVariantForm.js
import React, { useState } from 'react';
import { Form, FormLayout, TextField, Button, Banner } from '@shopify/polaris';
// Import the createShopifyVariant function
import { createShopifyVariant } from '../apis';

function NewVariantForm({ products, commonOptions = [], defaultValues = {}, onSuccess, onError }) {
  const [formValues, setFormValues] = useState(defaultValues);

  const handleSubmit = async () => {
    // Implement logic to create variants for each selected product
    console.log('Creating variants:', formValues);

    // Prepare the variant data
    const variantData = {
      options: commonOptions.map(option => formValues[option.name] || ''),
      price: formValues.price,
      compareAtPrice: formValues.compareAtPrice,
      // sku: formValues.sku,
      // barcode: formValues.barcode,
      // inventoryQuantity: formValues.inventoryQuantity,
      // weight: formValues.weight,
      // weightUnit: formValues.weightUnit,
      // requiresShipping: formValues.requiresShipping,
      // taxable: formValues.taxable,
      // inventoryManagement: formValues.inventoryManagement,
      // inventoryPolicy: formValues.inventoryPolicy,
      // fulfillmentServiceId: formValues.fulfillmentServiceId,
      // harmonizedSystemCode: formValues.harmonizedSystemCode,
      // countryCodeOfOrigin: formValues.countryCodeOfOrigin,
      // provinceCodeOfOrigin: formValues.provinceCodeOfOrigin,
      // imageSrc: formValues.imageSrc,
      // metafields: formValues.mestafields, // This should be an array of metafield objects
    };

    // Get the product IDs and image IDs
    const productData = products.map(product => ({
      id: product.id,
      mediaId: product.mediaId
    }));

    try {
      // Call the API to create variants
      const result = await createShopifyVariant(variantData, productData);
      console.log('Variants created successfully:', result);
      onSuccess('Variants created successfully');
    } catch (error) {
      console.error('Error creating variants:', error);
      onError(error.message || 'An error occurred while creating variants');
    }
  };

  const handleChange = (field) => (value) => {
    setFormValues({ ...formValues, [field]: value });
  };

  if (!products || products.length === 0) {
    return (
      <Banner status="warning">
        Please select products before creating variants.
      </Banner>
    );
  }

  if (commonOptions.length === 0) {
    return (
      <Banner status="warning">
        The selected products don't have any common options.
      </Banner>
    );
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormLayout>
        {commonOptions.map((option) => (
          <TextField
            key={option.name}
            label={option.name}
            value={formValues[option.name] || ''}
            onChange={handleChange(option.name)}
          />
        ))}
        <TextField
          label="Price"
          type="number"
          value={formValues.price || ''}
          onChange={handleChange('price')}
        />
        <TextField
          label="Compare at price"
          type="number"
          value={formValues.compareAtPrice || ''}
          onChange={handleChange('compareAtPrice')}
        />
        {/* <TextField
          label="SKU"
          value={formValues.sku || ''}
          onChange={handleChange('sku')}
        />
        <TextField
          label="Barcode"
          value={formValues.barcode || ''}
          onChange={handleChange('barcode')}
        /> */}
        {/* <TextField
          label="Inventory Quantity"
          type="number"
          value={formValues.inventoryQuantity || ''}
          onChange={handleChange('inventoryQuantity')}
        /> */}
        {/* <TextField
          label="Weight"
          type="number"
          value={formValues.weight || ''}
          onChange={handleChange('weight')}
        />
        <Select
          label="Weight Unit"
          options={['KILOGRAMS', 'GRAMS', 'POUNDS', 'OUNCES']}
          value={formValues.weightUnit || ''}
          onChange={handleChange('weightUnit')}
        />
        <Checkbox
          label="Requires Shipping"
          checked={formValues.requiresShipping || false}
          onChange={handleChange('requiresShipping')}
        />
        <Checkbox
          label="Taxable"
          checked={formValues.taxable || false}
          onChange={handleChange('taxable')}
        />
        <Select
          label="Inventory Management"
          options={['SHOPIFY', 'FULFILLMENT_SERVICE', 'NOT_MANAGED']}
          value={formValues.inventoryManagement || ''}
          onChange={handleChange('inventoryManagement')}
        />
        <Select
          label="Inventory Policy"
          options={['DENY', 'CONTINUE']}
          value={formValues.inventoryPolicy || ''}
          onChange={handleChange('inventoryPolicy')}
        />
        <TextField
          label="Fulfillment Service ID"
          value={formValues.fulfillmentServiceId || ''}
          onChange={handleChange('fulfillmentServiceId')}
        />
        <TextField
          label="Harmonized System Code"
          value={formValues.harmonizedSystemCode || ''}
          onChange={handleChange('harmonizedSystemCode')}
        />
        <TextField
          label="Country Code of Origin"
          value={formValues.countryCodeOfOrigin || ''}
          onChange={handleChange('countryCodeOfOrigin')}
        />
        <TextField
          label="Province Code of Origin"
          value={formValues.provinceCodeOfOrigin || ''}
          onChange={handleChange('provinceCodeOfOrigin')}
        /> */}
        {/* <TextField
          label="Image Source URL"
          value={formValues.imageSrc || ''}
          onChange={handleChange('imageSrc')}
        /> */}
        {/* Note: Metafields would require a more complex input structure */}
        <Button submit primary>
          Create Variants
        </Button>
      </FormLayout>
    </Form>
  );
}

export default NewVariantForm;