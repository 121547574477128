export const isExpired = (token) => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        const decoded = JSON.parse(jsonPayload);
        console.log(decoded, decoded.exp, Math.floor(Date.now() / 1000), decoded.exp < Math.floor(Date.now() / 1000));
        return decoded.exp < Math.floor(Date.now() / 1000);
    } catch (error) {
        console.error('Error decoding token:', error);
        return true; // Assume token is expired if it can't be decoded
    }
};
