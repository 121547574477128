import axios from 'axios';

// const BASE_URL = 'http://localhost:5003';
const BASE_URL = 'https://pvcbackend.purpleapps.co';

const getAuthToken = () => {
    const authData = localStorage.getItem('authToken');
    if (authData) {
        const { token } = JSON.parse(authData);
        return token;
    }
    return null;
};

export const getShopifyProducts = async () => {
    try {
        const token = getAuthToken();
        if (!token) {
            throw new Error('No authentication token found');
        }
        const response = await axios.get(`${BASE_URL}/shopify/products`, {
            headers: {
                'Authorization': token
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching Shopify products:', error);
        throw error;
    }
};

export const createShopifyVariant = async (variantData, productIds) => {
    try {
        const token = getAuthToken();
        if (!token) {
            throw new Error('No authentication token found');
        }
        const response = await axios.post(`${BASE_URL}/shopify/variant`, {
            variantData,
            productIds
        }, {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error creating Shopify variant:', error);
        throw error;
    }
};
