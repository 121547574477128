// src/components/AddVariantsPage.js
import React, { useState, useEffect } from 'react';
import { isExpired } from '../utils/jwtUtils';
import {
  Page,
  Layout,
  Card,
  Button,
  Modal,
  TextContainer,
  Banner,
  Badge,
  Form,
  FormLayout,
  TextField,
} from '@shopify/polaris';
import ProductList from './ProductList';
import NewVariantForm from './NewVariantForm';

function AddVariantsPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [commonOptions, setCommonOptions] = useState([]);
  const [error, setError] = useState(null);
  const [isValidToken, setIsValidToken] = useState(true);
  const [shopName, setShopName] = useState('');
  const [variantMessage, setVariantMessage] = useState(null);
  const [variantMessageStatus, setVariantMessageStatus] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const urlToken = params.get('token');
    const storedAuthData = localStorage.getItem('authToken');

    let token = null;
    let isTokenValid = false;

    if (urlToken) {
      if (!isExpired(urlToken)) {
        token = urlToken;
        isTokenValid = true;
        const expiryDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000); // 1 day from now
        localStorage.setItem('authToken', JSON.stringify({
          token: token,
          expiry: expiryDate.toISOString()
        }));

        // Remove token from URL
        params.delete('token');
        const newUrl = `${window.location.pathname}${params.toString() ? `?${params.toString()}` : ''}`;
        window.history.replaceState({}, '', newUrl);
      } else {
        console.error('Invalid token in URL');
      }
    } else if (storedAuthData) {
      const { token: storedToken } = JSON.parse(storedAuthData);
      if (!isExpired(storedToken)) {
        token = storedToken;
        isTokenValid = true;
      } else {
        console.error('Expired token in localStorage');
      }
    }

    setIsValidToken(isTokenValid);
  }, []);

  const handleProductSelection = (products) => {
    console.log('Selected products:', products); // Debugging log
    if (!Array.isArray(products) || products.length === 0) {
      setError('No products selected');
      setSelectedProducts([]);
      setCommonOptions([]);
    } else {
      setError(null);
      setSelectedProducts(products);
      const options = findCommonOptions(products);
      setCommonOptions(options);
    }
    setIsModalOpen(false);
  };

  const findCommonOptions = (products) => {
    if (products.length === 0) return [];

    // Get the options from the first product
    const firstProductOptions = products[0].options;

    // Check if all products have the same number of options and option names
    const isCommon = products.every(product =>
      product.options.length === firstProductOptions.length &&
      product.options.every((option, index) =>
        option.name === firstProductOptions[index].name
      )
    );

    return isCommon ? firstProductOptions : [];
  };

  // New function to get default values
  const getDefaultValues = () => {
    if (selectedProducts.length === 0) return {};

    const firstProduct = selectedProducts[0];
    console.log("firstProduct", firstProduct);
    return {
      price: firstProduct.price,
      compareAtPrice: firstProduct.compareAtPrice,
      sku: '',
      barcode: '',
      inventoryQuantity: 0,
      weight: firstProduct.weight || 0,
      weightUnit: firstProduct.weightUnit || 'KILOGRAMS',
      requiresShipping: true,
      taxable: true,
      inventoryManagement: 'SHOPIFY',
      inventoryPolicy: 'DENY',
      fulfillmentServiceId: '',
      harmonizedSystemCode: '',
      countryCodeOfOrigin: '',
      provinceCodeOfOrigin: '',
      imageSrc: firstProduct.image,
    };
  };

  const handleShopNameSubmit = () => {
    window.location.href = `https://pvcbackend.purpleapps.co/auth?shop=${shopName}`;
  };

  const handleVariantSuccess = (message) => {
    setVariantMessage(message);
    setVariantMessageStatus('success');
  };

  const handleVariantError = (errorMessage) => {
    setVariantMessage(errorMessage);
    setVariantMessageStatus('critical');
  };

  if (!isValidToken) {
    return (
      <Page>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <Form onSubmit={handleShopNameSubmit}>
                <FormLayout>
                  <TextField
                    label="Shop Name"
                    value={shopName}
                    onChange={setShopName}
                    autoComplete="off"
                    helpText="Enter your shop name to proceed"
                    placeholder='shopname.myshopify.com'
                  />
                  <Button submit primary>Proceed</Button>
                </FormLayout>
              </Form>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    );
  }

  return (
    <Page
      title="Add Variants"
      titleMetadata={selectedProducts.length > 0 && (
        <Badge status="success">{selectedProducts.length} products selected</Badge>
      )}
    >
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer spacing="tight">
              <h2>Select Products</h2>
              <p>Choose the products you want to add variants to.</p>
              <div style={{ marginTop: '1rem' }}>
                <Button primary onClick={() => setIsModalOpen(true)}>Select Products</Button>
              </div>
            </TextContainer>
          </Card>
        </Layout.Section>

        {error && (
          <Layout.Section>
            <Banner status="critical">{error}</Banner>
          </Layout.Section>
        )}

        {variantMessage && (
          <Layout.Section>
            <Banner
              status={variantMessageStatus}
              onDismiss={() => {
                setVariantMessage(null);
                setVariantMessageStatus('');
              }}
            >
              {variantMessage}
            </Banner>
          </Layout.Section>
        )}

        {selectedProducts.length > 0 && (
          <Layout.Section>
            <Card sectioned>
              {commonOptions.length > 0 ? (
                <NewVariantForm
                  products={selectedProducts}
                  commonOptions={commonOptions}
                  defaultValues={getDefaultValues()} // Pass default values
                  onSuccess={handleVariantSuccess}
                  onError={handleVariantError}
                />
              ) : (
                <Banner status="warning">
                  The selected products don't have the same options structure.
                </Banner>
              )}
            </Card>
          </Layout.Section>
        )}
      </Layout>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Select Products"
        large
      >
        <Modal.Section>
          <ProductList onSelect={handleProductSelection} />
        </Modal.Section>
      </Modal>
    </Page>
  );
}

export default AddVariantsPage;